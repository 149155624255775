import React, {useEffect, useState, useRef} from 'react'
import {useTranslation} from "react-i18next";
import {useForm} from 'react-hook-form'
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from 'yup'
import {MapContainer, Marker, TileLayer, useMap} from "react-leaflet";
import L from "leaflet";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import "leaflet/dist/leaflet.css";

import {
    Autocomplete, Breadcrumbs,
    Button, Card,
    FormControl,
    FormLabel,
    Grid,
    Input,
    Snackbar, Step, StepIndicator, Stepper, Typography,
    stepClasses, stepIndicatorClasses, AutocompleteOption, AspectRatio, Box, styled
} from "@mui/joy";
import Swal from "sweetalert2";
import {
    Add,
    Check,
    ChevronRight, Delete,
    Euro,
    AddPhotoAlternate, ChevronLeft
} from "@mui/icons-material";
import {Link} from "react-router-dom";
import axios from "axios";
import {EditorState} from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'draft-js/dist/Draft.css';

import {get, mutlipart_post, post} from "../../../api/api";
import {CLayout} from "../../../components";
import {jwtDecode} from "jwt-decode";

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const customIcon = L.icon({
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});

export const VCreateEvent = (): React.ReactElement => {
    const { t } = useTranslation()

    const [snackbar, setSnackbar] = useState<{
        open: boolean,
        color: "danger" | "primary" | "neutral" | "success" | "warning" | undefined,
        message: string,
    }>({
        open: false,
        color: 'success',
        message: ''
    })

    const setBanner = (file: any) => {
        console.log(file)
        void mutlipart_post({url: 'file_uploads', data: {file: file}})
            .then((res) => {
                console.log(res)
                setFirstFormData({
                    icon: {
                       url: res.data,
                       name: file.name
                    },
                    ...firstFormData
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const [selectedLocalisation, setSelectedLocalisation] = useState();
    const [localisations, setLocalisations] = useState([]);
    const [step, setStep] = useState<number>(1)
    const [address, setAddress] = useState<string>('')
    const [city, setCity] = useState<string>('')

    const [firstFormData, setFirstFormData] = useState<any>({})
    const updateFirstFormData = (key: string, value: any) => {
        firstFormData[key] = value;
        setFirstFormData({
            ...firstFormData,
        })
    }
    const checkFirstForm = () => {
        console.log(firstFormData)
        if (!firstFormData.name ||
            //!firstFormData.icon.url ||
            !editorState ||
            !firstFormData.startDate ||
            !firstFormData.startHour ||
            !firstFormData.endDate ||
            !firstFormData.endHour ||
            !firstFormData.price ||
            !firstFormData.ticket_link
        ) {
            Swal.fire({
                title: 'Des informations sont manquantes',
                text: 'Veuillez vérifiez les informations renseignées.',
                icon: 'error'
            })
         } else {
            setStep(2)
        }
    }

    const [secondFormData, setSecondFormData] = useState<any>({})
    const updateSecondFormDate = (key: string, value: any) => {
        secondFormData[key] = value;
        setSecondFormData({
            ...secondFormData
        })
    }
    const checkSecondForm = () => {
        console.log(secondFormData)
        if (
            !secondFormData.localisation ||
            !secondFormData.location_name ||
            !secondFormData.address ||
            !secondFormData.city
        ) {
            Swal.fire({
                title: 'Des informations sont manquantes',
                text: 'Veuillez vérifiez les informations renseignées.',
                icon: 'error'
            })
        } else {
            setStep(3)
         }
    }

    const [thirdFormData, setThirdFormData] = useState<any[]>([])
    const [selectedSubCategories, setSelectedSubCategories] = useState<any>()
    const checkThirdForm = () => {
        console.log(thirdFormData)
        if (thirdFormData.length === 0) {
            Swal.fire({
                title: 'Des informations sont manquantes',
                text: 'Veuillez vérifiez les informations renseignées.',
                icon: 'error'
            })
        } else {
            let subCateg: string[] = [];
            thirdFormData.map((item: any) => {
                subCateg.push(
                    `/sub-categories/${item.id}`
                )
            })
            setSelectedSubCategories({
                'subCategories' : Object.values(subCateg)
            })
            setStep(4)
        }
    }

    const [fourthFormData, setFourthFormData] = useState<any[]>([])
    const checkFourthForm = () => {
        console.log(fourthFormData)
        if (fourthFormData.length === 0) {
            Swal.fire({
                title: 'Des informations sont manquantes',
                text: 'Veuillez vérifiez les informations renseignées.',
                icon: 'error'
            })
        } else {
            setStep(5)
        }
    }

    const [newArtiste, setNewArtiste] = useState<string>('');
    const [artistes, setArtistes] = useState<string[]>([]);
    const addArtiste = () => {
        if (newArtiste.length > 0) {
            setArtistes([
                ...artistes,
                newArtiste
            ])

            setFourthFormData([
                ...artistes,
                newArtiste
            ])
        }
        setNewArtiste('')
    }
    const deleteArtiste = (id: number) => {
        if (window.confirm("Êtes-vous sûr de vouloir retirer cet artiste ?")) {
            let list = artistes;
            list = list.filter((_, index) => index !== id);
            setArtistes(list);
            setFourthFormData(list)
        }
    }

    const [searchCateg, setSearchCateg] = useState<any[]>([]);

    const [orga, setOrga] = useState({
        name: '',
        logo: ''
    })
    const [orgaName, setOrgaName] = useState<string>('')
    const [orgaLogo, setOrgaLogo] = useState<string>('')
    const addOrga = () => {
        setOrga({
            name: orgaName,
            logo: orgaLogo
        })
    }
    const setLogo = (file: any) => {
        console.log(file)
        void mutlipart_post({url: 'file_uploads', data: {file: file}})
            .then((res) => {
                console.log(res)
                setOrgaLogo(res.data.url)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const [latLng, setLatLng] = useState<any>([46.59822, 2.49561])
    const [zoom, setZoom] = useState<number>(5)

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );

    const getCoords = (addr: string, zoom: number) => {
        if (addr === "") {
            if (!selectedLocalisation) {
                setLatLng([46.59822, 2.49561])
                setZoom(5)
            } else {
                setZoom(8)
            }
        } else {
            void axios.get(`https://nominatim.openstreetmap.org/search?q=${addr}&format=json&limit=1`)
                .then((response) => {
                    if (response.data[0]?.lat && response.data[0]?.lon) {
                        setLatLng([
                            response.data[0]?.lat,
                            response.data[0]?.lon
                        ])
                        setZoom(zoom)
                    }
                })
        }
    }

    const createEventValidate = yup.object({
        name: yup.string().required(),
        start_date: yup.date().required(),
        end_date: yup.date().required(),
        location_name: yup.string().required(),
        address: yup.string().required(),
        city: yup.string().required(),
        price: yup.number().required(),
        ticketing_link: yup.string().required(),
    })

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(createEventValidate)
    })

    const submit = async (data: any) => {
        data['start_date'] = `${firstFormData.startDate}T${firstFormData.startHour}`
        data['end_date'] = `${firstFormData.endDate}T${firstFormData.endHour}`

        const formValues = {
            ...data,
            description: editorState.getCurrentContent().getPlainText('\u0001'),
            ...secondFormData,
            ...selectedSubCategories,
            artists: fourthFormData,
            organizer_name: orgaName,
            postal_code: '',
            location_coordinates_gps_x: latLng[0],
            location_coordinates_gps_y: latLng[1],
            status: "VALIDATED"
        }

        await post({url: 'events', data: formValues})
            .then(res => {
                setSnackbar({
                    open: true,
                    color: 'success',
                    message: 'Votre évènement à bien été crée.'
                })
                window.location.href = `/events/show?id=${res.data.id}`
            })
            .catch(err => {
                setSnackbar({
                    open: true,
                    color: 'danger',
                    message: 'Une erreur est survenue.'
                })
            });
    }
    const submitFailed = () => {
        Swal.fire({
            title: 'Une erreur est survenue',
            text: 'Veuillez vérifiez les informations transmisent.',
            icon: 'error'
        })
    }

    useEffect(() => {
        if (address && city) {
            getCoords(`${address}, ${city}`, 13)
        }
    }, [address, city, getCoords])

    useEffect(() => {
        document.title = `EevenPRO - ${t('Créer un évènement')}`
    }, [t]);

    useEffect(() => {
        void get({url: 'localisations'})
            .then(res => {
                setLocalisations(res.data)
            })
            .catch(err => console.log(err))

        void get({url: 'sub-categories'})
            .then(res => {
                setSearchCateg(res.data)
            })
            .catch(err => console.log(err))

        const token = localStorage.getItem('token') || ''
        const decoded = jwtDecode(token)
        console.log(decoded)
    }, []);

    const fileInputRef = useRef<any>(null);

    const handleAspectRatioClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const UpdateMapView = () => {
        const map = useMap()
        useEffect(() => {
            if (map) {
                map.setView(latLng, zoom)
            }
        }, [map]);

        return null
    }

    return (
        <CLayout>
            <section id={'VCreateEvent'}>
                <Card className={'rounded-none my-4 drop-shadow-lg'} variant={'outlined'}>
                    <div className={'flex items-center gap-4 justify-between w-full'}>
                        <Breadcrumbs separator={<ChevronRight/>} className={'!p-0'}>
                            <Link to={'/'}>{t('EevenPRO')}</Link>
                            <Link to={'/events'}>{t('Liste des évènements')}</Link>
                            <Typography>{t('Ajouter un évènement')}</Typography>
                        </Breadcrumbs>
                    </div>
                </Card>

                <form onSubmit={handleSubmit(submit, submitFailed)} className={'mt-8'}>
                    <Stepper size={'lg'} className={'!mx-5 !my-4'} sx={{
                        [`& .${stepIndicatorClasses.root}`]: {
                            backgroundColor: '#d7d7d7'
                        },
                        [`& .${stepClasses.root}::after`]: {
                            height: 2,
                        },
                        [`& .${stepClasses.completed}`]: {
                            [`& .${stepIndicatorClasses.root}`]: {
                                borderColor: '#343a9c',
                                bgcolor: '#343a9c',
                            },
                            '&::after': {
                                bgcolor: '#343a9c',
                            },
                        },
                    }}>
                        <Step active={step === 1} completed={step > 1} indicator={
                            <StepIndicator
                                variant={step <= 1 ? 'soft' : 'solid'}
                            >
                                {step <= 1 ? 1 : <Check />}
                            </StepIndicator>
                        }><span className={'text-sm'}>{t('Informations sur l\'évènement')}</span></Step>

                        <Step active={step === 2} completed={step > 2} indicator={
                            <StepIndicator
                                variant={step <= 2 ? 'soft' : 'solid'}
                                color={step < 2 ? 'neutral' : 'primary'}
                            >
                                {step <= 2 ? 2 : <Check />}
                            </StepIndicator>
                        }><span className={'text-sm'}>{t('Lieu de l\'évènement')}</span></Step>

                        <Step active={step === 3} completed={step > 3} indicator={
                            <StepIndicator
                                variant={step <= 3 ? 'soft' : 'solid'}
                                color={step < 3 ? 'neutral' : 'primary'}
                            >
                                {step <= 3 ? 3 : <Check />}
                            </StepIndicator>
                        }><span className={'text-sm'}>{t('Catégories')}</span></Step>

                        <Step active={step === 4} completed={step > 4} indicator={
                            <StepIndicator
                                variant={step <= 4 ? 'soft' : 'solid'}
                                color={step < 4 ? 'neutral' : 'primary'}
                            >
                                {step <= 4 ? 4 : <Check />}
                            </StepIndicator>
                        }><span className={'text-sm'}>{t('Artistes')}</span></Step>

                        <Step active={step === 5} indicator={
                            <StepIndicator
                                variant={step <= 5 ? 'soft' : 'solid'}
                                color={step < 5 ? 'neutral' : 'primary'}
                            >
                                {step <= 5 ? 5 : <Check />}
                            </StepIndicator>
                        }><span className={'text-sm'}>{t('Organisateur')}</span></Step>
                    </Stepper>

                    {step === 1 && (
                        <>
                            <Grid container spacing={2} className={'!mt-3 !mx-3'}>
                                <Grid xs={4} xsOffset={8} className={'!text-end'}>
                                    <Button className={`!py-3 ${!['name', 'startDate', 'startHour', 'endDate', 'endHour', 'price', 'ticket_link'].every(
                                        key => Object.keys(firstFormData).includes(key)
                                    ) ? 'next' : ''}`} size={'lg'} onClick={checkFirstForm}>
                                        Suivant <ChevronRight className={'!ml-2'} />
                                    </Button>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid xs={12}>
                                    <Card className={'cards_events mx-5 !p-5'}>
                                        <Grid container spacing={2}>
                                            <Grid xs={8}>
                                                <FormControl error={!!errors.name?.message} className={'mt-2'}>
                                                    <FormLabel>
                                                        {t('Nom de l\'évenement')}
                                                        <span className={'text-red-500'}>*</span>
                                                    </FormLabel>
                                                     <Input
                                                        {...register('name')}
                                                        variant={'outlined'}
                                                        onChange={(e) => {
                                                            updateFirstFormData('name', e.target.value)
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid xs={12}>
                                                <FormControl>
                                                    <FormLabel>
                                                        {t('Description de l\'événement')}
                                                        <span className={'text-red-500'}>*</span>
                                                    </FormLabel>
                                                    <Editor
                                                        editorState={editorState}
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={setEditorState}
                                                        wrapperStyle={{
                                                            background: '#FFFDFA',
                                                            borderRadius: '5px',
                                                            border: '1px solid #cdd7e1',
                                                        }}
                                                        editorStyle={{
                                                            height: '200px',
                                                            padding: '10px'
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>

                                <Grid xs={12}>
                                    <Card className={'cards_events mx-5 !p-5'}>
                                        <Grid container spacing={2}>
                                            <Grid xs={12}>
                                                <FormControl>
                                                    <FormLabel>
                                                        {t('Affiche de l\'évenement')}
                                                        <span className={'text-red-500'}>*</span>
                                                    </FormLabel>
                                                    <AspectRatio
                                                        onClick={handleAspectRatioClick}
                                                        sx={{
                                                            border: '2px dashed',
                                                            borderColor: 'neutral.outlinedBorder',
                                                            borderRadius: 'md',
                                                            cursor: 'pointer',
                                                            '&:hover': { borderColor: 'primary.outlinedBorder' },
                                                        }}
                                                        maxHeight={150}
                                                    >
                                                        <Box textAlign={"center"} flexDirection={'column'}>
                                                            <AddPhotoAlternate fontSize="large" color="disabled" />
                                                            <Typography>Image</Typography>
                                                            {firstFormData.icon?.name && (
                                                                <Typography className={'mt-2'}>
                                                                    Fichier sélectionné : {firstFormData.icon?.name}
                                                                </Typography>
                                                            )}
                                                        </Box>
                                                        <VisuallyHiddenInput ref={fileInputRef} type="file" onChange={(e) => {
                                                            if (e.target?.files) {
                                                                setBanner(e.target?.files[0])
                                                            }
                                                        }} />
                                                    </AspectRatio>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>

                                <Grid xs={12}>
                                    <Card className={'cards_events mx-5 !p-5'}>
                                        <Grid container spacing={2}>
                                            <Grid xs={12}>
                                                <FormLabel>
                                                    {t('Début')}
                                                    <span className={'text-red-500'}>*</span>
                                                </FormLabel>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Input
                                                    {...register('start_date')}
                                                    variant={'outlined'}
                                                    type={'date'}
                                                    
                                                    onChange={(e) => {
                                                        updateFirstFormData('startDate', e.target.value)
                                                    }}
                                                />
                                            </Grid>
                                            <Grid xs={3}>
                                                <Input
                                                    variant={'outlined'}
                                                    type={'time'}
                                                    onChange={(e) => {
                                                        updateFirstFormData('startHour', e.target.value)
                                                    }}
                                                />
                                            </Grid>

                                            <Grid xs={12}>
                                                <FormLabel>
                                                    {t('Fin')}
                                                    <span className={'text-red-500'}>*</span>
                                                </FormLabel>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Input
                                                    {...register('end_date')}
                                                    variant={'outlined'}
                                                    type={'date'}
                                                    slotProps={{
                                                        input: {
                                                            min: firstFormData.startDate ?? new Date(),
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        updateFirstFormData('endDate', e.target.value)
                                                    }}
                                                />
                                            </Grid>
                                            <Grid xs={3}>
                                                <Input
                                                    variant={'outlined'}
                                                    type={'time'}
                                                    
                                                    onChange={(e) => {
                                                        updateFirstFormData('endHour', e.target.value)
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>

                                <Grid xs={12}>
                                    <Card className={'cards_events mx-5 !p-5'}>
                                        <Grid container spacing={2}>
                                            <Grid xs={3}>
                                                <FormControl error={!!errors.price?.message}>
                                                    <FormLabel>
                                                        {t('Prix de l\'évenement')}
                                                        <span className={'text-red-500'}>*</span>
                                                    </FormLabel>
                                                    <Input
                                                        {...register('price')}
                                                        variant={'outlined'}
                                                        type={'number'}
                                                        defaultValue={0.00}
                                                        endDecorator={<Euro />}
                                                        slotProps={{
                                                            input: {
                                                                step: '0.01',
                                                                min: '0',
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            updateFirstFormData('price', parseFloat(e.target.value))
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid xs={6}>
                                                <FormControl error={!!errors.ticketing_link?.message}>
                                                    <FormLabel>
                                                        {t('Lien vers la billeterie')}
                                                        <span className={'text-red-500'}>*</span>
                                                    </FormLabel>
                                                    <Input
                                                        {...register('ticketing_link')}
                                                        variant={'outlined'}
                                                        
                                                        onChange={(e) => {
                                                            updateFirstFormData('ticket_link', e.target.value)
                                                        }}
                                                    />
                                                    <small><i>Coller l'url de la billeterie</i></small>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            </Grid>
                        </>
                    )}

                    {step === 2 && (
                        <>
                            <Grid container spacing={2} className={'!mt-3 !mx-3'}>
                                <Grid xs={4}>
                                    <Button className={'!py-3 prev'} variant={'outlined'} size={'lg'} color={'neutral'} onClick={() => setStep(1)}>
                                        <ChevronLeft className={'!mr-2'} /> Précédent
                                    </Button>
                                </Grid>

                                <Grid xs={4} xsOffset={4} className={'!text-end'}>
                                    <Button className={`!py-3 ${!['localisation', 'location_name', 'address', 'city'].every(
                                        key => Object.keys(secondFormData).includes(key)
                                    ) ? 'next' : ''}`} size={'lg'} onClick={checkSecondForm}>
                                        Suivant <ChevronRight className={'!ml-2'} />
                                    </Button>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid xs={12}>
                                    <Card className={'cards_events mx-5 !p-5 h-[400px]'}>
                                        <Grid container spacing={2} className={'!h-full'}>
                                            <Grid xs={7} className={'!h-full'}>
                                                <MapContainer
                                                    center={latLng}
                                                    zoom={zoom}
                                                    style={{ height: "100%", width: "100%", borderRadius: "1rem" }}
                                                >
                                                    <TileLayer
                                                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                                                        attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, Imagery by <a href="https://carto.com/attributions">CARTO</a>'
                                                    />
                                                    {address && (
                                                        <Marker position={latLng} icon={customIcon} />
                                                    )}
                                                    <UpdateMapView />
                                                </MapContainer>
                                            </Grid>

                                            <Grid container spacing={2} xs={5}>
                                                <Grid xs={12}>
                                                    <FormControl>
                                                        <FormLabel>{t('Localisation')}</FormLabel>
                                                        <Autocomplete
                                                            onChange={(event, newValue) => {
                                                                setSelectedLocalisation(newValue?.name)
                                                                updateSecondFormDate('localisation', `/localisations/${newValue?.id}`)
                                                                let addr = newValue?.name
                                                                if (addr) {
                                                                    addr = addr.split(' ')
                                                                    getCoords(addr[0], 8)
                                                                } else {
                                                                    getCoords('', 5)
                                                                }
                                                            }}
                                                            options={localisations}
                                                            value={localisations.find((loc: any) => loc.name === selectedLocalisation || null)}
                                                            getOptionLabel={(option: any) => option.name || ''}
                                                            isOptionEqualToValue={(option: any, value: any) => option.name === value}
                                                            
                                                        />
                                                    </FormControl>
                                                </Grid>

                                                {selectedLocalisation && (
                                                    <>
                                                        <Grid xs={12}>
                                                            <FormControl error={!!errors.location_name?.message}>
                                                                <FormLabel>{t('Lieu de l\'évenement')}</FormLabel>
                                                                <Input
                                                                    {...register('location_name')}
                                                                    onChange={(e) => {
                                                                        updateSecondFormDate('location_name', e.target.value)
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </Grid>

                                                        <Grid xs={12}>
                                                            <FormControl error={!!errors.address?.message}>
                                                                <FormLabel>{t('Adresse complète')}</FormLabel>
                                                                <Input
                                                                    {...register('address')}
                                                                    
                                                                    onChange={(e) => {
                                                                        updateSecondFormDate('address', e.target.value)
                                                                        const timer = setTimeout(() => {
                                                                            setAddress(e.target.value)
                                                                        }, 2000);

                                                                        return () => clearTimeout(timer);
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </Grid>

                                                        <Grid xs={12}>
                                                            <FormControl error={!!errors.city?.message}>
                                                                <FormLabel>{t('Ville')}</FormLabel>
                                                                <Input
                                                                    {...register('city')}
                                                                    onChange={(e: any) => {
                                                                        updateSecondFormDate('city', e.target.value)
                                                                        const timer = setTimeout(() => {
                                                                            setCity(e.target.value)
                                                                        }, 2000);

                                                                        return () => clearTimeout(timer);
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            </Grid>
                        </>
                    )}

                    {step === 3 && (
                        <>
                            <Grid container spacing={2} className={'!mt-3 !mx-3'}>
                                <Grid xs={4}>
                                    <Button className={'!py-3 prev'} size={'lg'} variant={'outlined'} color={'neutral'} onClick={() => setStep(2)}>
                                        <ChevronLeft className={'!mr-2'} /> Précédent
                                    </Button>
                                </Grid>

                                <Grid xs={4} xsOffset={4} className={'!text-end'}>
                                    <Button className={`!py-3 ${thirdFormData.length === 0 ? 'next' : ''}`} size={'lg'} onClick={checkThirdForm}>
                                        Suivant <ChevronRight className={'!ml-2'} />
                                    </Button>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid xs={12}>
                                    <Card variant={'soft'} className={'mx-5 !p-5 cards_events'}>
                                        <FormControl>
                                            <FormLabel>Ajouter une catégorie</FormLabel>
                                            <Autocomplete
                                                autoComplete
                                                options={searchCateg}
                                                value={thirdFormData}
                                                multiple
                                                onChange={(_, newValue) => {
                                                    setThirdFormData(newValue)
                                                }}
                                                noOptionsText="Aucune catégorie ne correspond à votre recherche"
                                                getOptionLabel={(option: any) => option?.category?.name && option?.name ? `${option.category.name} (${option.name})` : ''}
                                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                                renderOption={(props, option) => (
                                                    <AutocompleteOption {...props}>
                                                        {option.category.name} ({option.name})
                                                    </AutocompleteOption>
                                                )}
                                                className={""}
                                            />
                                        </FormControl>
                                    </Card>
                                </Grid>
                            </Grid>
                        </>
                    )}

                    {step === 4 && (
                        <>
                            <Grid container spacing={2} className={'!mt-3 !mx-3'}>
                                <Grid xs={4}>
                                    <Button className={'!py-3 prev'} size={'lg'} variant={'outlined'} color={'neutral'} onClick={() => setStep(3)}>
                                        <ChevronLeft className={'!mr-2'} /> Précédent
                                    </Button>
                                </Grid>

                                <Grid xs={4} xsOffset={4} className={'!text-end'}>
                                    <Button className={`!py-3 ${fourthFormData.length === 0 ? 'next' : ''}`} size={'lg'} onClick={checkFourthForm}>
                                        Suivant <ChevronRight className={'!ml-2'} />
                                    </Button>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid xs={12}>
                                    <Card className={'mx-5 !p-5 cards_events'}>
                                        <Grid container spacing={2}>
                                            <Grid xs={6}>
                                                <FormControl>
                                                    <FormLabel>Nom de l'artiste</FormLabel>
                                                    <Input
                                                        variant={'outlined'}
                                                        value={newArtiste}
                                                        onChange={(e) => setNewArtiste(e.currentTarget.value)}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid xs={'auto'}>
                                                <Button className={'!mt-6'} color={'success'} onClick={addArtiste}>
                                                    <Add />
                                                </Button>
                                            </Grid>

                                            <Grid container spacing={2} xs={12}>
                                                {artistes.map((artiste, key) => (
                                                    <Grid key={key} xs={4}>
                                                        <Card key={key} className={'hover:drop-shadow-lg'}>
                                                            <Grid container spacing={2}>
                                                                <Grid spacing={0} container xs={9}>
                                                                    <Grid xs={12}>
                                                                        <Typography level={'h4'}>
                                                                            {artiste}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid xs={3}>
                                                                    <Button variant={'outlined'} color={'danger'} onClick={() => deleteArtiste(key)}>
                                                                        <Delete />
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </Card>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            </Grid>
                        </>
                    )}

                    {step === 5 && (
                        <>
                            <Grid container spacing={2} className={'!mt-3 !mx-3'}>
                                <Grid xs={4}>
                                    <Button className={'!py-3 prev'} size={'lg'} variant={'outlined'} color={'neutral'}
                                            onClick={() => setStep(4)}>
                                        <ChevronLeft className={'!mr-2'} /> Précédent
                                    </Button>
                                </Grid>

                                <Grid xs={4} xsOffset={4} className={'!text-end'}>
                                    <Button type={'submit'} className={'!py-3 submit'} size={'lg'} color={'success'}>
                                        Ajouter l'évènement
                                    </Button>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid xs={12}>
                                    <Card className={'mx-5 !p-5 cards_events'}>
                                        <Grid container spacing={2}>
                                            <Grid xs={3}>
                                                <FormControl>
                                                    <FormLabel>Nom de l'organisateur</FormLabel>
                                                    <Input
                                                        variant={'outlined'}
                                                        
                                                        onChange={(e) => setOrgaName(e.target.value)}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid xs={3}>
                                                <FormControl>
                                                    <FormLabel>Logo de l'organisateur</FormLabel>
                                                    <Input
                                                        variant={'outlined'}
                                                        type={'file'}
                                                        onChange={(e) => {
                                                            if (e.target?.files) {
                                                                setLogo(e.target?.files[0])
                                                            }
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid xs={'auto'}>
                                                <Button className={'!mt-6'} color={'success'} onClick={addOrga}>
                                                    <Add />
                                                </Button>
                                            </Grid>

                                            <Grid xs={12}>
                                                {orga.name && (
                                                    <Card className={'hover:drop-shadow-lg'}>
                                                        <Grid container spacing={2}>
                                                            <Grid xs={'auto'}>
                                                                <div className={'w-[100px] h-[65px]'} style={{
                                                                    backgroundImage: `url(${orga.logo})`,
                                                                    backgroundRepeat: 'no-repeat',
                                                                    backgroundSize: 'contain',
                                                                }} />
                                                            </Grid>

                                                            <Grid spacing={0} container xs={9}>
                                                                <Grid xs={12}>
                                                                    <Typography level={'h4'}>
                                                                        {orga.name}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid xs={'auto'} className={'!w-max'}>
                                                                <Button variant={'outlined'} color={'danger'} className={'!w-full !h-full'}
                                                                        onClick={() => setOrga({name: '', logo: ''})}>
                                                                    <Delete/>
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Card>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </form>

                <Snackbar open={snackbar.open} variant={'soft'} color={snackbar.color} autoHideDuration={3000} onClose={() => setSnackbar({...snackbar, open: false})}>
                    {snackbar.message}
                </Snackbar>
            </section>
        </CLayout>
    )
}