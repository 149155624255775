import React, {useState, useEffect, useContext} from 'react'
import {Button, Card, Checkbox, FormControl, FormLabel, Grid, Input, List, ListItem} from "@mui/joy";
import {Save} from "@mui/icons-material";
import {jwtDecode} from "jwt-decode";

import {get, put} from "../../../api/api";
import {CLayout} from "../../../components";
import '../style.scss'
import {TitleContext} from "../../../components/Layout";

export const VShowAbonnement = () => {
    const { setTitle } = useContext(TitleContext) || {}
    const [id, setId] = useState<string>('')
    const [subscription, setSubscription] = useState<any>({})
    const [scopes, setScopes] = useState<string[]>([])
    const [selectedScopes, setSelectedScopes] = useState<string[]>([]);

    const handleScopeChange = (scope: string) => {
        setSelectedScopes((prevScopes) =>
            prevScopes.includes(scope)
                ? prevScopes.filter((item) => item !== scope)
                : [...prevScopes, scope]
        );
    };

    const handleInputChange = (key: string, value: string) => {
        setSubscription((prev: any) => ({
            ...prev,
            [key]: value,
        }));
    }

    useEffect(() => {
        const query = new URLSearchParams(window.location.search)
        setId(query.get('id') ?? '')

        const token = localStorage.getItem("token") || '';
        const decode: any = jwtDecode(token)
        if (!decode.roles.includes('ROLE_ADMIN')) {
            window.location.href = '/'
        }

        setScopes(decode?.scopes || [])
    }, []);

    useEffect(() => {
        if (setTitle) {
            setTitle('Détail de l\'abonnement')
        }
    }, [setTitle]);

    useEffect(() => {
        void get({url: `subscription_plans/${id}`})
            .then((res) => {
                setSubscription(res.data)
                setSelectedScopes(res.data.scopes || [])
            })
            .catch((err) => {
                console.log(err)
            })
    }, [id]);

    const submit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const data = Object.fromEntries(formData.entries());
        console.log({
            ...data,
            scopes: selectedScopes
        })

        void put({url: 'subscription_plans', data: {
                ...data,
                scopes: selectedScopes
            }})
            .then(res => window.location.href = '/plans')
            .catch(err => console.log(err))
    };

    return (
        <CLayout>
            <section id={'VShowAbonnement'}>
                <form onSubmit={submit}>
                    <Grid container spacing={2}>
                        <Grid xs={12}>
                            <Card className={'cards'}>
                                <Grid container spacing={2}>
                                    <Grid xs={12}>
                                        <FormControl>
                                            <FormLabel>
                                                Nom
                                            </FormLabel>
                                            <Input
                                                name={'name'}
                                                value={subscription.name}
                                                onChange={(e) => handleInputChange('name', e.target.value)}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={12}>
                                        <FormControl>
                                            <FormLabel>
                                                Stipe ID
                                            </FormLabel>
                                            <Input
                                                name={'stripe_plan_id'}
                                                value={subscription.stripe_plan_id}
                                                onChange={(e) => handleInputChange('stripe_plan_id', e.target.value)}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={12}>
                                        <FormLabel>
                                            Scope
                                        </FormLabel>
                                        <List>
                                            {scopes.map((scope: string, key: number) => (
                                                <ListItem key={key}>
                                                    <Checkbox
                                                        value={scope}
                                                        label={scope}
                                                        checked={selectedScopes.includes(scope)}
                                                        onChange={() => handleScopeChange(scope)}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Grid>

                                    <Grid xs={4} xsOffset={8}>
                                        <Button type={'submit'} className={'float-right'}>
                                            <Save className={'!mr-2'}/> Enregistrer
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </form>
            </section>
        </CLayout>
    )
}