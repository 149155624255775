import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import {
    Button,
    Card,
    FormControl,
    FormLabel,
    Grid,
    Input,
    List,
    ListItem,
    Typography,
    IconButton, Autocomplete, Snackbar
} from "@mui/joy";
import {Save, Delete, Add, VisibilityOff, Visibility} from "@mui/icons-material";

import { CLayout } from "../../../components";
import { get, patch } from "../../../api/api";
import "../style.scss";

export const VShowUser = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();

    const [user, setUser] = useState<any>({});
    const [id] = useState<string>(searchParams.get("id") || "0");
    const [source] = useState<string>(searchParams.get("source") || "admin");
    const [errors, setErrors] = useState<{ name?: string; email?: string }>({});
    const [newRole, setNewRole] = useState<string>("");
    const [decodedUser, setDecodedUser] = useState<any>({});
    const [visible, setVisible] = useState<boolean>(false);
    const [snackbar, setSnackbar] = useState<{
        open: boolean,
        color: "danger" | "primary" | "neutral" | "success" | "warning" | undefined,
        message: string,
    }>({
        open: false,
        color: 'success',
        message: ''
    })

    useEffect(() => {
        document.title = `EevenPro - ${t("Détail utilisateur")}`;
    }, [t]);

    useEffect(() => {
        const token = localStorage.getItem("token") || "";
        const decode: any = jwtDecode(token);

        if (source === 'admin') {
            if (!decode.roles.includes("ROLE_ADMIN")) {
                window.location.href = "/";
            }
        }

        setDecodedUser(decode)
    }, [source]);

    useEffect(() => {
        void get({ url: `users/${id}` })
            .then((res) => setUser(res.data))
            .catch((err) => console.log(err));
    }, [id]);

    const handleInputChange = (key: string, value: string) => {
        setUser((prev: any) => ({
            ...prev,
            [key]: value,
        }));
    };

    const validateForm = () => {
        let newErrors: { name?: string; email?: string } = {};

        if (!user.name || user.name.trim() === "") {
            newErrors.name = "Le nom est requis.";
        }
        if (!user.email) {
            newErrors.email = "L'email est requis.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const addRole = () => {
        if (newRole && !user.roles.includes(newRole)) {
            setUser((prev: any) => ({
                ...prev,
                roles: [...prev.roles, newRole],
            }));
        }
        setNewRole("");
    };

    const removeRole = (role: string) => {
        if (role !== "ROLE_ADMIN" || decodedUser.id !== id) {
            setUser((prev: any) => ({
                ...prev,
                roles: prev.roles.filter((r: string) => r !== role),
            }));
        }
    };

    const submit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!validateForm()) {
            return;
        }

        const formData = new FormData(event.currentTarget);
        const data = Object.fromEntries(formData.entries());

        if (data.plain_password !== data.retype_password) {
            setSnackbar({
                open: true,
                color: 'danger',
                message: 'Les mots de passe ne correspondent pas.'
            })
        } else {
            void patch({
                url: `users/${id}`,
                data: {
                    ...data,
                    roles: user.roles,
                    subscription_plans: user.subscription_plans,
                },
            })
                .then(() => {
                    if (source === 'admin') {
                        window.location.href = "/users"
                    } else {
                        window.location.href = '/';
                    }
                })
                .catch((err) => console.log(err));
        }
    };

    return (
        <CLayout>
            <section id={"VShowUser"}>
                <form onSubmit={submit}>
                    <Card className={"cards"}>
                        <Grid container spacing={2}>
                            <Grid xs={12}>
                                <FormControl error={!!errors.name}>
                                    <FormLabel>Nom</FormLabel>
                                    <Input
                                        name={"name"}
                                        value={user.name || ""}
                                        onChange={(e) =>
                                            handleInputChange("name", e.target.value)
                                        }
                                    />
                                    {errors.name && (
                                        <Typography color="danger" level="body-sm">
                                            {errors.name}
                                        </Typography>
                                    )}
                                </FormControl>
                            </Grid>

                            <Grid xs={12}>
                                <FormControl error={!!errors.email}>
                                    <FormLabel>Email</FormLabel>
                                    <Input
                                        name={"email"}
                                        value={user.email || ""}
                                        onChange={(e) =>
                                            handleInputChange("email", e.target.value)
                                        }
                                    />
                                    {errors.email && (
                                        <Typography color="danger" level="body-sm">
                                            {errors.email}
                                        </Typography>
                                    )}
                                </FormControl>
                            </Grid>

                            {
                                source !== 'admin' && (
                                    <>
                                        <Grid xs={12}>
                                            <FormControl>
                                                <FormLabel>Mot de passe</FormLabel>
                                                <Input
                                                    name={'plain_password'}
                                                    type={visible ? 'text': 'password'}
                                                    endDecorator={(
                                                        <IconButton onClick={() => setVisible(!visible)}>
                                                            {visible ? <VisibilityOff /> : <Visibility /> }
                                                        </IconButton>
                                                    )}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid xs={12}>
                                            <FormControl>
                                                <FormLabel>Validation du mot de passe</FormLabel>
                                                <Input
                                                    name={'retype_password'}
                                                    type={visible ? 'text': 'password'}
                                                    endDecorator={(
                                                        <IconButton onClick={() => setVisible(!visible)}>
                                                            {visible ? <VisibilityOff /> : <Visibility /> }
                                                        </IconButton>
                                                    )}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </>
                                )
                            }

                            { source === 'admin' && (
                                <Grid xs={12}>
                                    <FormLabel>Roles</FormLabel>
                                    <List>
                                        {user.roles?.map((role: string, key: number) => (
                                            <ListItem key={key} sx={{display: "flex", alignItems: "center"}}>
                                                <Typography component="span">
                                                    • {role}
                                                </Typography>
                                                {source === 'admin' && (user.roles.length > 1 && (role !== "ROLE_ADMIN" || decodedUser.id !== id)) && (
                                                    <IconButton
                                                        color="danger"
                                                        onClick={() => removeRole(role)}
                                                    >
                                                        <Delete/>
                                                    </IconButton>
                                                )}
                                            </ListItem>
                                        ))}
                                    </List>

                                    <Grid container spacing={2} alignItems="center">
                                        <Grid xs={8}>
                                            <Autocomplete
                                                placeholder="Associer un nouveau rôle"
                                                options={['ROLE_ADMIN', 'ROLE_USER']}
                                                variant={'outlined'}
                                                onChange={(e, newValue: any) => setNewRole(newValue)}
                                            />
                                        </Grid>
                                        <Grid xs={4}>
                                            <Button onClick={addRole} startDecorator={<Add/>}>
                                                Associer
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}

                            <Grid xs={4} xsOffset={8}>
                                <Button type={"submit"} className={"float-right"}>
                                    <Save className={"!mr-2"} /> Enregistrer
                                </Button>
                            </Grid>
                        </Grid>
                    </Card>
                </form>
            </section>

            <Snackbar open={snackbar.open} variant={'solid'} color={snackbar.color} autoHideDuration={3000} onClose={() => setSnackbar({...snackbar, open: false})}>
                {snackbar.message}
            </Snackbar>
        </CLayout>
    );
};