import React, {useEffect, useState} from 'react'
import {
    Breadcrumbs,
    Button,
    Card, Divider,
    Grid,
    IconButton,
    Table,
    Typography
} from "@mui/joy";
import {Link} from "react-router-dom";

import {CLayout} from "../../components"
import {destroy, get} from '../../api/api'

import './style.scss'
import {Add, ChevronRight, Delete, Visibility} from "@mui/icons-material";
import {jwtDecode} from "jwt-decode";

export const VAbonnements = () => {
    const [plans, setPlans] = useState<any[]>([])

    const deleteAbonnement = (id: string) => {
        if (window.confirm('Êtes-vous sur de vouloir retirer ce plan d\'abonnement ?')) {
            void destroy({url: `subscription_plans/${id}`})
                .then((res) => {
                    setPlans(plans.filter((plan) => plan.id !== id))
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    useEffect(() => {
        const token = localStorage.getItem('token') || ''
        const decode: any = jwtDecode(token)

        if (!decode.roles.includes('ROLE_ADMIN')) {
            window.location.href = '/'
        }

        void get({url: `subscription_plans?pageSize=100&current=${1}`})
            .then(res => {
                setPlans(res.data)
            })
            .catch(err => console.log(err))
    }, []);

    return (
        <CLayout>
            <section id={'VAbonnements'}>
                <Card className={'rounded-none my-4 drop-shadow-lg'} variant={'outlined'}>
                    <Grid container spacing={2} className={'items-center'}>
                        <Grid xs={4}>
                            <Breadcrumbs separator={<ChevronRight />}>
                                <Link to={'/'}>Eeven PRO</Link>
                                <Typography>Plan d'abonnements</Typography>
                            </Breadcrumbs>
                        </Grid>

                        <Grid xs={4} xsOffset={4} className={'text-end'}>
                            <Button onClick={() => window.location.href = '/plans/create'}>
                                <Add /> Ajouter un plan
                            </Button>
                        </Grid>
                    </Grid>
                </Card>

                <Grid container spacing={2} className={'justify-between items-center'}>
                    <Grid xs={4}>
                        <Typography level={'h4'} className={'!mt-4 !text-3xl !text-black'}>
                            Plan d'abonnements
                        </Typography>
                        <Divider className={'!w-[85%] !bg-black !h-[2px] !mt-1 !mb-4'} />
                    </Grid>
                </Grid>

                <Table>
                    <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Stripe ID</th>
                            <th>Actions</th>
                        </tr>
                    </thead>

                    <tbody>
                        {plans.map((plan: any, index: number) => (
                            <tr key={index}>
                                <td>{plan.name}</td>
                                <td>{plan.stripe_plan_id}</td>
                                <td>
                                    <IconButton onClick={() => {
                                        window.location.href = `/plans/show?id=${plan.id}`;
                                    }}>
                                        <Visibility />
                                    </IconButton>

                                    <IconButton onClick={() => deleteAbonnement(plan.id)}>
                                        <Delete />
                                    </IconButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </section>
        </CLayout>
    )
}