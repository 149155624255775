import React, {useEffect, useState, createContext} from 'react';
import {
    Dropdown,
    MenuButton,
    Menu,
    MenuItem,
    Avatar,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemDecorator,
    ListItemContent,
    Card,
    CardContent,
    Button,
    Link
} from '@mui/joy';
import Swal from 'sweetalert2';
import {jwtDecode} from "jwt-decode";
import {Close, KeyboardArrowRight} from "@mui/icons-material";

import Logo from '../../assets/l-logo.svg';
import '../../styles/main.scss';
import '../../styles/events.scss';
import {SidebarItems} from "../Sidebar";
import {ISidebarItems} from "../../types/sidebarItems";

interface TitleContextType {
    title: string;
    setTitle: (title: string) => void;
}

export const TitleContext = createContext<TitleContextType | undefined>(undefined);

export const CLayout = ({children}: { children: any }): React.ReactElement => {
    const [user, setUser] = useState<any>({});
    const [title, setTitle] = useState(document.title);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [openedSubmenu, setOpenedSubmenu] = useState<string>('');
    const [admin, setAdmin] = useState<boolean>(false);

    const handleOpenedSubmenu = (id: string) => {
        setOpenedSubmenu(openedSubmenu === id ? '' : id);
    };

    useEffect(() => {
        console.log(title)
    }, [title]);

    useEffect(() => {
        const token = localStorage.getItem('token') ?? '';
        try {
            const decode: any = jwtDecode(token);
            setAdmin(decode.roles.includes('ROLE_ADMIN'));
            setUser({
                id: decode.id,
                username: decode.name
            });
        } catch (error) {
            console.warn("Invalid or missing token");
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            const newWidth = window.innerWidth;
            setInnerWidth(newWidth);

            if (newWidth < 900) {
                Swal.fire({
                    title: "Écran trop petit !",
                    text: "Merci d'utiliser ce logiciel sur un ordinateur.",
                    icon: 'error',
                    showConfirmButton: false,
                    allowOutsideClick: false,
                });
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <TitleContext.Provider value={{title, setTitle}}>
            {innerWidth > 900 && (
                <section id={'layout'} className={'flex'}>
                    <aside className={'h-screen sticky top-0 z-[50]'}>
                        <div id={'sidebar'} className={'h-full fixed w-[20rem] p-6'}>
                            <a href={'/'}>
                                <h2 className={'flex items-center gap-4 text-xl font-medium text-default-900 mb-6'}>
                                    <img src={Logo} width={30} height={30} alt={'logo_eeven'}/>
                                    eevenPro
                                </h2>
                            </a>
                            <List className={'h-[80vh] overflow-y-auto gap-2'}>
                                {SidebarItems.filter((item) =>
                                    ['abonnements', 'users'].includes(item.id) ? admin : true
                                ).map((item, key) => (
                                    <ListItem key={key} nested>
                                        <ListItem>
                                            <ListItemButton
                                                className={`!rounded-2xl 
                                                    !text-white hover:!text-black transition duration-300 
                                                    ${window.location.pathname === item.url || openedSubmenu === item.id ? '!bg-gray-300 !text-black' : ''}
                                                `}
                                                selected={window.location.pathname === item.url || openedSubmenu === item.id}
                                                onClick={() => handleOpenedSubmenu(item.id)}
                                                component={'a'}
                                                href={item.url}
                                            >
                                                <ListItemDecorator>{item.icon}</ListItemDecorator>
                                                <ListItemContent>{item.title}</ListItemContent>
                                                {item.submenu && (openedSubmenu === item.id ? <Close/> :
                                                    <KeyboardArrowRight/>)}
                                            </ListItemButton>
                                        </ListItem>
                                    </ListItem>
                                ))}
                            </List>
                            <div className={'absolute bottom-0 left-0 right-0 m-4'}>
                                <Card className={'custom-bg-milk'}>
                                    <CardContent>
                                        <p>Besoin d'aide ?</p>
                                        <p className={'mb-8 text-gray-500'}>N'hésitez pas à nous contacter</p>
                                        <Button endDecorator={<KeyboardArrowRight/>}>Prendre contact</Button>
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                    </aside>
                    <div className={'flex flex-col flex-1 ml-[20rem]'}>
                        <nav className={'mainnav ml-[20rem] py-3 px-4 fixed left-0 right-0 flex items-center justify-between'}>
                            <p className={'text-xl'}>{title}</p>
                            <Dropdown>
                                <MenuButton variant={'plain'} slots={{root: IconButton}}>
                                    <Avatar/>
                                </MenuButton>
                                <Menu placement={'bottom-end'} variant={'plain'}>
                                    <MenuItem>
                                        <Link color={'neutral'} underline={'none'}>{user.username}</Link>
                                    </MenuItem>
                                    <MenuItem>
                                        <Link href={`/users/show?id=${user?.id}&source=user`} color={'neutral'}
                                              underline={'none'}>
                                            Modifier mon profil
                                        </Link>
                                    </MenuItem>

                                    <hr className={'dropdown-user m-2'} />

                                    <MenuItem>
                                        <Link
                                            color={'neutral'}
                                            underline={'none'}
                                            href={'#'}
                                            onClick={() => {
                                                localStorage.removeItem('token');
                                                window.location.href = '/login';
                                            }}
                                        >
                                            Se deconnecter
                                        </Link>
                                    </MenuItem>
                                </Menu>
                            </Dropdown>
                        </nav>
                        <hr className={'content-separator'} />

                        <div className={'content mt-20 m-4 z-auto'}>
                            {children}
                        </div>
                    </div>
                </section>
            )}
        </TitleContext.Provider>
    );
};
