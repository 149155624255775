import React, { useState, useEffect } from 'react'
import {useTranslation} from "react-i18next";
import {
    Button,
    Card,
    CardContent,
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    Input,
    Snackbar,
    Typography
} from "@mui/joy";
import {Key, Visibility, VisibilityOff} from "@mui/icons-material";

import {post} from "../../../api/api";
import './style.scss'
import Logo from "../../../assets/colored_logo.svg";

export const VUpdatePassword = () => {
    const { t } = useTranslation()

    const [code, setCode] = useState<string>('')
    const [visible, setVisible] = useState<boolean>(false)

    const [snackbar, setSnackbar] = useState<{
        open: boolean,
        color: "danger" | "primary" | "neutral" | "success" | "warning" | undefined,
        message: string,
    }>({
        open: false,
        color: 'success',
        message: ''
    });

    const [submitting, setSubmitting] = useState<boolean>(false)

    useEffect(() => {
        document.title = `EevenPRO - ${t('Finalisez votre inscription')}`;
    }, [t]);

    useEffect(() => {
        const query = new URLSearchParams(window.location.search)
        setCode(query.get('code') ?? '')
    }, []);

    const submit = (event: React.FormEvent) => {
        event.preventDefault()
        const data = new FormData(event.target as HTMLFormElement);
        const password = data.get('password');
        const verifyPassword = data.get('confirmPassword');

        if (password !== verifyPassword) {
            setSnackbar({
                open: true,
                color: 'danger',
                message: 'Les mots de passe doivent être identiques.'
            })
        } else {
            setSubmitting(true);
            void post({url: `forgot-password/${code}`, data: {
                password: password
            }})
                .then(res => {
                    window.location.href = '/login'
                })
                .catch(err => {
                    setSnackbar({
                        open: true,
                        color: 'danger',
                        message: 'Une erreur est survenue.'
                    })
                })
                .finally(() => {
                    setSubmitting(false)
                })
        }
    }

    return (
        <section id={'VUpdatePassword'}>
            <Grid container spacing={2} className={'!px-8 !pt-4 items-center'}>
                <Grid xs={4} className={'login_logo'}>
                    <img src={Logo} alt={'logo colored'}/>
                    <Typography level={'h2'} className={'firstTitle font-bold'}>eeven<span
                        className={'secondTitle'}>Pro</span></Typography>
                </Grid>

                <Grid xs={4} xsOffset={4} className={'text-end'}>
                    <a className={'!text-white hover:underline'} href={'mailto:eeve.contact@gmail.com'} target="_blank" rel="noopener noreferrer">Besoin d'aide ?</a>
                </Grid>

                <Grid xs={12}>
                    <Typography level={'h1'} className={'text-center firstTitle font-bold'}>
                        Bienvenue sur eeven<span className={'secondTitle'}>Pro</span>
                    </Typography>
                </Grid>
            </Grid>

            <Card className={'card'} variant={'plain'}>
                <CardContent className={'justify-center'}>
                    <Typography className={'text-center'} component={'h2'} level={'h2'}>{t('Choisissez un nouveau mot de passe')}</Typography>
                    <form className={'flex flex-col gap-4 !mt-4 !mx-12'} onSubmit={submit}>
                        <FormControl>
                            <FormLabel>{t('Mot de passe')}</FormLabel>
                            <Input
                                name={'password'}
                                color={'neutral'}
                                variant={'soft'}
                                type={visible ? 'text' : 'password'}
                                placeholder={t('Mot de passe')}
                                startDecorator={<Key/>}
                                endDecorator={
                                    <IconButton onClick={() => setVisible(!visible)}>
                                        {visible ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                }
                            />
                        </FormControl>

                        <FormControl>
                            <FormLabel>{t('Confirmez le mot de passe')}</FormLabel>
                            <Input
                                name={'confirmPassword'}
                                color={'neutral'}
                                variant={'soft'}
                                type={visible ? 'text' : 'password'}
                                placeholder={t('Mot de passe')}
                                startDecorator={<Key/>}
                                endDecorator={
                                    <IconButton onClick={() => setVisible(!visible)}>
                                        {visible ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                }
                            />
                        </FormControl>

                        <Button loading={submitting} type="submit">
                            {t('Valider')}
                        </Button>
                    </form>
                </CardContent>
            </Card>

            <Snackbar open={snackbar.open} variant={'solid'} color={snackbar.color} autoHideDuration={3000}
                      onClose={() => setSnackbar({...snackbar, open: false})}>
                {snackbar.message}
            </Snackbar>
        </section>

    )
}