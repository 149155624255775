export const loggedRoutes: string[] = [
    '',
    '/',

    '/events',
    '/events/',
    '/events/create',
    '/events/create/',
    '/events/show',
    '/events/show/',
    '/plans',
    '/plans/',
    '/plans/create',
    '/plans/create/',
    '/plans/show',
    '/plans/show/',
    '/users',
    '/users/',
    '/users/show',
    '/users/show/',
]
