import React from 'react'
import {Box, Card, Typography, Divider} from "@mui/joy";
import { CheckCircleOutlined } from "@mui/icons-material";

import Logo from '../../assets/colored_logo.svg'
import './style.scss'

export const VPaiementSucced = () => {
 return (
     <section id={'VPaiementSucced'}>
         <div className={'!pt-8 !mx-12 flex gap-2 items-center'}>
            <img className={'!w-[80px]'} src={Logo} />
            <Typography className={'firstTitle'} level={'h1'}>
                eeven<span className={'secondTitle'}>Pro</span>
            </Typography>
         </div>
         <Box
             sx={{
                 display: "flex",
                 justifyContent: "center",
                 alignItems: "center",
                 mt: 8
             }}
         >
             <Card
                 variant="outlined"
                 sx={{
                     width: 400,
                     padding: 3,
                     backgroundColor: "#FFFDFA",
                     borderRadius: "16px",
                     boxShadow: "lg",
                     textAlign: "center",
                 }}
             >
                 <div className={'w-full text-center'}>
                    <CheckCircleOutlined sx={{ color: "#2ECC71", fontSize: 50, mb: 2 }} />
                 </div>

                 <Typography
                     level="h4"
                     sx={{ fontWeight: "bold", color: "#000", mb: 1 }}
                 >
                     Paiement Accepté !
                 </Typography>

                 <Typography
                     sx={{ color: "#000", mb: 3 }}
                 >
                     Merci pour votre abonnement. Consultez vos e-mails pour finaliser votre inscription.
                 </Typography>

                 <Card
                     variant="soft"
                     sx={{
                         backgroundColor: "#EEE",
                         color: "#000",
                         padding: 2,
                         borderRadius: "12px",
                         mb: 3,
                     }}
                 >
                     <Typography sx={{ fontWeight: "700", color: "#000" }}>
                         Détails du paiement
                     </Typography>
                     <Divider />
                     <Typography sx={{ mb: 1 }} className={'!text-black'}>
                         Numéro de transaction : <strong>#123456789</strong>
                     </Typography>
                     <Typography sx={{ mb: 1 }} className={'!text-black'}>
                         Montant payé : <strong>49,99 €</strong>
                     </Typography>
                 </Card>
             </Card>
         </Box>
     </section>
 )
}