import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from 'yup'
import {Link} from "react-router-dom";

import Logo from '../../assets/colored_logo.svg'
import {post} from "../../api/api";

import {
    Button,
    Card,
    CardContent,
    FormControl,
    FormHelperText,
    FormLabel, Grid,
    IconButton,
    Input, Snackbar,
    Typography
} from "@mui/joy";
import {Email, Error, Key, Visibility, VisibilityOff} from "@mui/icons-material";

import './style.scss'

export const VLogin = (): React.ReactElement => {
    const { t } = useTranslation()

    const loginValidate = yup.object({
        username: yup.string().required(t('Un email est requis.')).email(t('L\'email n\'est pas valide.')),
        password: yup.string().required(t('Un mot de passe est requis.')).min(6, t('Le mot de passe doit faire 6 caractères minimum.')),
    })

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(loginValidate)
    })

    const [visible, setVisible] = useState<boolean>(false)
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [snackbar, setSnackbar] = useState<{
        open: boolean,
        color: "danger" | "primary" | "neutral" | "success" | "warning" | undefined,
        message: string,
    }>({
        open: false,
        color: 'success',
        message: ''
    })

    useEffect(() => {
        document.title = `EevenPRO - ${t('Connexion')}`
    }, [t])

    const submit = async (data: any) => {
        setSubmitting(true)
        await post({url: 'login_check', data})
            .then(res => {
                localStorage.setItem('token', res.data.token)
                setSnackbar({
                    open: true,
                    color: 'success',
                    message: 'Vous êtes désormais connecté.'
                })
                window.location.href = '/'
            })
            .catch(err => {
                setSnackbar({
                    open: true,
                    color: 'danger',
                    message: 'Identifiant ou mot de passe incorrect.'
                })
            }).finally(() => {
                setSubmitting(false)
            })
    }

    return (
        <section id={'VLogin'}>
            <Grid container spacing={2} className={'!px-8 !pt-4 items-center'}>
                <Grid xs={4} className={'login_logo'}>
                    <img src={Logo} alt={'logo colored'}/>
                    <Typography level={'h2'} className={'firstTitle font-bold'}>eeven<span
                        className={'secondTitle'}>Pro</span></Typography>
                </Grid>

                <Grid xs={4} xsOffset={4} className={'text-end'}>
                    <a className={'!text-white hover:underline'} href={'mailto:eeve.contact@gmail.com'} target="_blank" rel="noopener noreferrer">Besoin d'aide ?</a>
                </Grid>

                <Grid xs={12}>
                    <Typography level={'h1'} className={'text-center firstTitle font-bold'}>
                        Bienvenue sur eeven<span className={'secondTitle'}>Pro</span>
                    </Typography>
                </Grid>
            </Grid>

            <Card className={'card'} variant={'plain'}>
                <CardContent className={'justify-center relative'}>
                    <div className={'gap-4'}>
                        <Typography className={'text-center'} component={'h1'} level={'h1'}>
                            {t('Connexion')}
                        </Typography>
                    </div>
                    <form className={'flex flex-col gap-4 mx-12'} onSubmit={handleSubmit(submit)}>
                        <FormControl error={!!errors.username}>
                            <FormLabel>{t('Email')}</FormLabel>
                            <Input
                                {...register('username')}
                                color={'neutral'}
                                variant={'soft'}
                                type={'email'}
                                placeholder={t('Email')}
                                startDecorator={<Email/>}
                            />
                            <FormHelperText>
                                {errors.username && <><Error/>{errors.username.message}</>}
                            </FormHelperText>
                        </FormControl>

                        <FormControl error={!!errors.password}>
                            <FormLabel>{t('Mot de passe')}</FormLabel>
                            <Input
                                {...register('password')}
                                color={'neutral'}
                                variant={'soft'}
                                type={visible ? 'text' : 'password'}
                                placeholder={t('Mot de passe')}
                                startDecorator={<Key/>}
                                endDecorator={
                                    <IconButton onClick={() => setVisible(!visible)}>
                                        {visible ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                }
                            />
                            <FormHelperText>
                                {errors.password && <><Error/>{errors.password.message}</>}
                            </FormHelperText>
                        </FormControl>

                        <Button loading={submitting} type={'submit'}>
                            {t('Connexion')}
                        </Button>
                    </form>

                    <Typography component={'p'} level={'body-md'} className={'text-center'}>
                        {t('Vous avez oubliez votre mot de passe ? ')}
                        <Link to={'/forgot-password'}
                              className={'text-blue-600 hover:underline'}>{t('Réinitialisez le')}</Link>
                    </Typography>
                </CardContent>
            </Card>

            <Snackbar open={snackbar.open} variant={'solid'} color={snackbar.color} autoHideDuration={3000}
                      onClose={() => setSnackbar({...snackbar, open: false})}>
                {snackbar.message}
            </Snackbar>
        </section>
    )
}
