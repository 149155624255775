import { createBrowserRouter } from 'react-router-dom'

import {
    VLogin,
    VReset,
    VEvents,
    VCreateEvent,
    VShowEvent,
    VTarifs,
    VAbonnements,
    VCreateAbonnement,
    VShowAbonnement,
    VDashboard,
    VUpdatePassword,
    VFinalizationRegister,
    VPaiementSucced,
    VPaiementRefused,
    VListUsers,
    VShowUser,
} from '../../views'

import {
    CNotFound
} from '../../components'

export const router = createBrowserRouter([
    { path: '/', Component: VDashboard },
    { path: 'login', Component: VLogin },
    { path: 'forgot-password', Component: VReset },
    { path: 'finalization-registration', Component: VFinalizationRegister },
    { path: 'paiement-succed', Component: VPaiementSucced },
    { path: 'paiement-refused', Component: VPaiementRefused },
    { path: 'tarifs', Component: VTarifs },
    { path: 'update-password', Component: VUpdatePassword },
    { path: '/', Component: VDashboard },
    { path: 'events', Component: VEvents },
    { path: 'events/create', Component: VCreateEvent },
    { path: 'events/show', Component: VShowEvent },
    { path: 'plans', Component: VAbonnements },
    { path: 'plans/create', Component: VCreateAbonnement },
    { path: 'plans/show', Component: VShowAbonnement },
    { path: 'users', Component: VListUsers },
    { path: 'users/show', Component: VShowUser },

    { path: '*', Component: CNotFound }
])
