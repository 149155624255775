import React, {useEffect, useState} from "react";

import {CLayout} from "../../components";
import {useTranslation} from "react-i18next";
import {
    Autocomplete,
    Breadcrumbs,
    Button,
    Card, Chip, Divider,
    Dropdown, Grid,
    Menu,
    MenuButton,
    Typography
} from "@mui/joy";
import {Link} from "react-router-dom";
import {Add, ChevronRight, FilterList} from "@mui/icons-material";

import {get} from "../../api/api";
import '../../styles/events.scss'
import './style.scss'
import {Pagination} from "@mui/material";

export const VEvents = (): React.ReactElement => {
    const { t } = useTranslation();

    const [events, setEvents] = useState<any[]>()

    const [categories, setCategories] = useState<any[]>([
        {
            "subCategories": [
                {
                    "id": 2,
                    "name": "string"
                }
            ],
            "id": 0,
            "name": "string"
        }
    ])
    const [subCategories, setSubCategories] = useState<any[]>([
        {
            category: {
                id: 0,
                name: "string"
            },
            slug: "string",
            id: 2,
            name: "string"
        }
    ])
    const [subCategoriesFiltered, setSubCategoriesFiltered] = useState<any[]>([
        {
            category: {
                id: 0,
                name: "string"
            },
            slug: "string",
            id: 2,
            name: "string"
        }
    ])
    const [localisation, setLocalisation] = useState<any[]>([
        {
            id: 0,
            name: "string"
        }
    ])

    const [filterCategories, setFilterCategories] = useState<string | null>()
    const [filterSubCategories, setFilterSubCategories] = useState<string | null>()
    const [filterLocalisation, setFilterLocalisation] = useState<string | null>()

    const [pagination, setPagination] = useState<number>(0)
    const [page, setPage] = useState<number>(1);

    const handleChange = (event: any, value: number) => {
        setPage(value);
    };

    useEffect(() => {
        void get({url: 'categories'})
            .then(res => setCategories(res.data))
            .catch(err => console.log(err))

        void get({url: 'sub-categories'})
            .then(res => {
                setSubCategories(res.data)
            })
            .catch(err => console.log(err))

        void get({url: 'localisations'})
            .then(res => setLocalisation(res.data))
            .catch(err => console.log(err))
    }, []);

    useEffect(() => {
        document.title = `EevenPRO - ${t('Liste des évènements')}`
    }, [t]);

    useEffect(() => {
        setSubCategoriesFiltered(subCategories)

        if (filterCategories) {
            setSubCategoriesFiltered(
                subCategories.filter((subCategorie) => subCategorie.category.name === filterCategories)
            )
        }
    }, [filterCategories, subCategories])

    useEffect(() => {
        const start = ((page - 1) * 10) + 1
        const end = ((page) * 10)

        void get({
            url: `events?_end=${end}&_start=${start}${filterSubCategories ? `&sub_categories[]=${filterSubCategories}` : ''}${filterLocalisation ? `&localisation[]=${filterLocalisation}` : ''}`
        })
            .then((res) => {
                setPagination(Math.ceil(parseInt((res.headers['content-range'])?.split('/')[1]) / 10));
                setEvents(res.data);
            })
            .catch(err => console.log(err));
    }, [page, filterSubCategories, filterLocalisation]);

    useEffect(() => {
        const start = ((page - 1) * 10) + 1
        const end = ((page) * 10)

        void get({url: `events?_end=${end}&_start=${start}`})
            .then((res) => {
                setPagination(Math.ceil(parseInt((res.headers['content-range']).split('/')[1]) / 10))
                setEvents(res.data)
            })
            .catch(err => console.log(err))
    }, [page])

    return (
        <CLayout>
            <section id={'VEvents'} className={'min-h-[600px]'}>
                <Card className={'rounded-none my-4 drop-shadow-lg'} variant={'outlined'}>
                    <div className={'flex items-center gap-4 justify-between w-full'}>
                        <Breadcrumbs separator={<ChevronRight />} className={'!p-0'}>
                            <Link to={'/'}>{t('EevenPRO')}</Link>
                            <Typography>{t('Liste des évènements')}</Typography>
                        </Breadcrumbs>

                        <div className={'flex gap-4'}>
                            <Dropdown>
                                <MenuButton variant={'outlined'} className={'hover:!bg-transparent hover:!scale-110 transition'}>
                                    <FilterList />
                                </MenuButton>

                                <Menu className={'w-[500px] !p-4 gap-4'}>
                                    <Typography>{t('Filtres')}</Typography>

                                    <div className={'flex justify-between gap-4'}>
                                        <Typography>{t('Catégories')}</Typography>
                                        <Autocomplete
                                            onChange={(event, newValue) => {
                                                setFilterCategories(newValue?.name || '')
                                            }}
                                            className={'w-[70%]'}
                                            options={categories}
                                            value={categories?.find((cat) => cat.name === filterCategories) || null}
                                            getOptionLabel={(option: any) => option?.name || ''}
                                            isOptionEqualToValue={(option: any, value: any) => option?.name === value}
                                        />
                                    </div>

                                    <div className={'flex justify-between gap-4'}>
                                        <Typography>{t('Sous-catégories')}</Typography>
                                        <Autocomplete
                                            onChange={(event, newValue) => {
                                                setFilterSubCategories(newValue?.id)
                                            }}
                                            className={'w-[70%]'}
                                            value={subCategories?.find((subCat) => subCat.id === filterSubCategories) || null}
                                            options={subCategoriesFiltered}
                                            getOptionLabel={(option: any) => `${option?.category?.name} (${option?.name})` || ''}
                                            isOptionEqualToValue={(option: any, value: any) => option?.id === value}
                                        />
                                    </div>

                                    <div className={'flex justify-between gap-4'}>
                                        <Typography>{t('Localisations')}</Typography>
                                        <Autocomplete
                                            onChange={(event, newValue) => {
                                                setFilterLocalisation(newValue?.id)
                                            }}
                                            className={'w-[70%]'}
                                            value={localisation?.find((loc) => loc.id === filterLocalisation) || null}
                                            options={localisation}
                                            getOptionLabel={(option: any) => option?.name || ''}
                                            isOptionEqualToValue={(option: any, value: any) => option?.id === value}
                                        />
                                    </div>
                                </Menu>
                            </Dropdown>

                            <Button color={'primary'} className={'!text-white hover:!scale-105 transition'} onClick={() => window.location.href = '/events/create'}>
                                {t('Ajouter un évènement')} <Add className={'!fill-white'} />
                            </Button>
                        </div>
                    </div>
                </Card>

                <Grid container spacing={2} className={'justify-between items-center'}>
                    <Grid xs={4}>
                        <Typography level={'h4'} className={'!mt-4 !text-3xl !text-black'}>
                            Mes évenements
                        </Typography>
                        <Divider className={'!w-[75%] !bg-black !h-[2px] !mt-1 !mb-4'} />
                    </Grid>

                    <Grid xs={8}>
                        <Pagination className={'!text-black'} count={pagination} shape="rounded" onChange={handleChange}/>
                    </Grid>
                </Grid>
                <Grid spacing={2} container className={'overflow-y-auto max-h-[550px]'}>
                    {
                        events?.map((event, key) => (
                            <div className={'w-full'} key={key}>
                                {showEvent(event, key)}
                            </div>
                        ))
                    }
                </Grid>
            </section>
        </CLayout>
    )
}

const showEvent = (event: any, key: number) => {
    return (
        <Grid key={key} xs={12}>
            <Card key={key} className={'z-1 hover:drop-shadow-lg hover:!scale-[0.98] duration-200 transition hover:cursor-pointer cardEvents'} onClick={() => window.location.href = `/events/show?id=${event.id}`}>
                <Grid container spacing={2}>
                    <Grid xs={'auto'}>
                        <div className={'w-[100px] h-[75px]'} style={{
                            backgroundImage: `url(${event.urls_image[0]})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                        }} />
                    </Grid>

                    <Grid spacing={0} container xs={6}>
                        <Grid xs={12}>
                            <Typography level={'h4'}>
                                {event.name}
                            </Typography>
                        </Grid>

                        <Grid xs={12}>
                            <span>{new Date(event.start_date).toLocaleString('fr-FR')} - {new Date(event.end_date).toLocaleString('fr-FR')}</span>
                        </Grid>
                    </Grid>

                    <Grid xs={4} container spacing={2} className={'justify-center stats'}>
                        <Grid xs={2} className={'flex flex-col gap-2 items-center justify-center ml-2'}>
                            <Chip className={'!px-4 !py-1'} color={'primary'}>{event?.views} Vues</Chip>
                        </Grid>

                        <Grid xs={2} className={'flex flex-col gap-2 items-center justify-center ml-4'}>
                            <Chip className={'!px-4 !py-1'} color={'primary'}>{event?.likes} Like</Chip>
                        </Grid>

                        <Grid xs={3} className={'flex flex-col gap-2 items-center justify-center ml-2'}>
                            <Chip className={'!px-4 !py-1'} color={'primary'}>{event?.interests} Intérêt</Chip>
                        </Grid>

                        <Grid xs={3} className={'flex flex-col gap-2 items-center justify-center ml-2'}>
                            <Chip className={'!px-4 !py-1'} color={'primary'}>{event?.shares} Partages</Chip>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    )
}