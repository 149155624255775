import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from 'yup'
import {Link} from "react-router-dom";

import {post} from "../../api/api";

import {
    Button,
    Card,
    CardContent,
    FormControl,
    FormHelperText,
    FormLabel, Grid,
    Input,
    Snackbar,
    Typography
} from "@mui/joy";
import { Email, Error } from "@mui/icons-material";

import './style.scss'
import Logo from "../../assets/colored_logo.svg";
export const VReset = (): React.ReactElement => {
    const { t } = useTranslation()

    const resetValidate = yup.object({
        email: yup.string().required(t('Un email est requis.')).email(t('L\'email n\'est pas valide.')),
    })

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(resetValidate)
    })

    const [submitting, setSubmitting] = useState<boolean>(false)
    const [snackbar, setSnackbar] = useState<{
        open: boolean,
        color: "danger" | "primary" | "neutral" | "success" | "warning" | undefined,
        message: string,
    }>({
        open: false,
        color: 'success',
        message: ''
    })

    useEffect(() => {
        document.title = `EevenPRO - ${t('Réinitialiser mon mot de passe')}`
    }, [t])

    const submit = async (data: any) => {
        setSubmitting(true)
        await post({url: 'forgot-password/', data})
            .then(res => {
                console.log(res)
                setSnackbar({
                    open: true,
                    color: 'success',
                    message: 'Si l\'adresse e-mail saisie est valide, un e-mail vous sera envoyé.'
                })
            })
            .catch(err => {
                setSnackbar({
                    open: true,
                    color: 'danger',
                    message: 'Une erreur est survenue.'
                })
            }).finally(() => {
                setSubmitting(false)
            })
    }

    return (
        <section id={'VReset'}>
            <Grid container spacing={2} className={'!px-8 !pt-4 items-center'}>
                <Grid xs={4} className={'login_logo'}>
                    <img src={Logo} alt={'logo colored'}/>
                    <Typography level={'h2'} className={'firstTitle font-bold'}>eeven<span
                        className={'secondTitle'}>Pro</span></Typography>
                </Grid>

                <Grid xs={4} xsOffset={4} className={'text-end'}>
                    <a className={'!text-white hover:underline'} href={'mailto:eeve.contact@gmail.com'} target="_blank" rel="noopener noreferrer">Besoin d'aide ?</a>
                </Grid>

                <Grid xs={12}>
                    <Typography level={'h1'} className={'text-center firstTitle font-bold'}>
                        Bienvenue sur eeven<span className={'secondTitle'}>Pro</span>
                    </Typography>
                </Grid>
            </Grid>

            <Card className={'card'} variant={'plain'}>
                <CardContent className={'justify-center'}>
                    <Typography className={'text-center'} component={'h1'} level={'h1'}>{t('Réinitialiser mon mot de passe')}</Typography>
                    <form className={'flex flex-col gap-4 mx-12'} onSubmit={handleSubmit(submit)}>
                        <FormControl error={!!errors.email}>
                            <FormLabel>{t('Email')}</FormLabel>
                            <Input
                                {...register('email')}
                                color={'neutral'}
                                variant={'soft'}
                                type={'email'}
                                placeholder={t('Email')}
                                startDecorator={<Email />}
                            />
                            <FormHelperText>
                                {errors.email && <><Error />{errors.email.message}</>}
                            </FormHelperText>
                        </FormControl>

                        <Button loading={submitting} type={'submit'}>
                            {t('Réinitialiser mon mot de passe')}
                        </Button>
                    </form>

                    <Typography component={'p'} level={'body-md'} className={'text-center'}>
                        {t('Vous avez déjà un compte ? ')}
                        <Link to={'/login'} className={'text-blue-600 hover:underline'}>{t('Connectez-vous.')}</Link>
                    </Typography>
                </CardContent>
            </Card>

            <Snackbar open={snackbar.open} variant={'solid'} color={snackbar.color} autoHideDuration={3000} onClose={() => setSnackbar({...snackbar, open: false})}>
                {snackbar.message}
            </Snackbar>
        </section>
    )
}
