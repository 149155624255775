import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { router } from './plugins/router';
import { loggedRoutes } from "./plugins/router/logged";
import { allRoutes } from "./plugins/router/allroutes";

import './styles/base.css';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const App = () => {
    const [token] = React.useState(localStorage.getItem('token') || '')
    const [notFound, setNotFound] = React.useState<boolean>(false)

    useEffect(() => {
        if (document.querySelector('.CNotFound') !== null || document.querySelector('#VTarifs') !== null) {
            setNotFound(true);
        }

        if (!['/tarifs', '/tarifs/'].includes(window.location.pathname)) {
            const token = localStorage.getItem('token');
            if (!allRoutes.includes(window.location.pathname)) {
                // Do nothing if the route is not in allRoutes
            } else if (!token && loggedRoutes.includes(window.location.pathname)) {
                window.location.href = '/login';
            } else if (token && !loggedRoutes.includes(window.location.pathname)) {
                window.location.href = '/';
            }
        }
    }, []);

    return (
        <div className={`${(!token && !notFound) ? 'dark-bg' : ''}`}>
            <RouterProvider router={router} />
        </div>
    );
}

root.render(
    <App />
);