import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import {
    Button,
    Card,
    Divider,
    Grid,
    List,
    ListDivider,
    ListItem,
    ListItemContent,
    ListItemDecorator,
    Typography
} from "@mui/joy";

import './style.scss'
import {Check, Close} from "@mui/icons-material";

export const VTarifs = () => {
    const {t} = useTranslation()
    const [plans, setPlans] = useState<any[]>([])

    useEffect(() => {
        setPlans([
            {
                name: 'Freemium',
                description: 'Profitez gratuitement des fonctionnalités essentielles pour démarrer.',
                price: 0,
                scope: [
                    'Avantage 1',
                    'Avantage 2',
                    'Avantage 3',
                    'Avantage 4'
                ],
                redirect: 'https://buy.stripe.com/test_bIY01P1DH1xSbDO5kl'
            },
            {
                name: 'Premium',
                description: 'Débloquez tout le potentiel avec des outils avancés et un support exclusif.',
                price: 20,
                scope: [
                    'Avantage 1',
                    'Avantage 2',
                    'Avantage 3',
                    'Avantage 4'
                ],
                redirect: 'https://buy.stripe.com/test_14k6qd5TXdgA5fqaEE'
            }
        ])
    }, []);

    useEffect(() => {
        document.title = `EevenPRO - ${t('Tarification')}`
    }, [t]);

    return (
        <section id={"VTarifs"}>
            <Grid container spacing={2} className={'gridTarif !m-8'}>
                <Grid xs={12}>
                    <Typography className={'tarifTitle !text-black text-center'} level={'h2'}>Découvrez nos abonnements</Typography>
                    <Divider className={'!w-[20%] !mx-auto !my-3 !h-[3px] !bg-black'} />
                </Grid>

                <Grid xs={2}></Grid>

                {plans.map((plan, key) => (
                    <Grid key={key} xs={4}>
                        <Card className={`drop-shadow-lg !p-4 ${key % 2 === 0 ? 'odd' : 'even'}`}>
                            <Typography className={'drop-shadow-md'} level={'h3'}>{plan.name}</Typography>
                            <Typography level={'body-md'} className={'!drop-shadow-md !-mt-2'}>{plan.description}</Typography>
                            <Typography level={'body-md'} className={'!drop-shadow-md !mb-4'}><span className={'font-bold !text-5xl'}>{plan.price}€</span> / mois</Typography>
                            <List>
                                {plan.scope.map((scope: string, index: number) => (
                                    <>
                                        <ListItem key={index} className={'!pl-0'}>
                                            <ListItemContent>{scope}</ListItemContent>
                                            <ListItemDecorator>
                                                {((index < 2 && key % 2 === 0) || (index < 3 && key % 2 === 1)) ? (
                                                    <Check />
                                                ) : (
                                                    <Close />
                                                )}

                                            </ListItemDecorator>
                                        </ListItem>
                                        {
                                            index < 3 && (
                                                <ListDivider />
                                            )
                                        }
                                    </>
                                ))}
                            </List>
                            <Button className={`fill-button !text-black !mt-4 buttonPricing hover:drop-shadow-xl transition duration-300`} onClick={() => window.location.href = plan.redirect}>S'abonner</Button>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </section>
    )
}