import {ISidebarItems} from "../../types/sidebarItems";
import {AttachMoney, Dashboard, PeopleAlt, Person, Subscriptions} from "@mui/icons-material";

export const SidebarItems: ISidebarItems[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        url: '/',
        icon: <Dashboard />,
    },
    {
        id: 'events',
        title: 'Évènements',
        url: '/events',
        icon: <PeopleAlt />
    },
    {
        id: 'tarifs',
        title: 'Tarification',
        url: '/tarifs',
        icon: <AttachMoney />
    },
    {
        id: 'abonnements',
        title: 'Abonnements',
        url: '/plans',
        icon: <Subscriptions />
    },
    {
        id: 'users',
        title: 'Utilisateurs',
        url: '/users',
        icon: <Person />,
    }
]