export const allRoutes: string[] = [
    '',
    '/',

    '/login',
    '/login/',
    '/forgot-password',
    '/forgot-password/',
    '/events',
    '/events/',
    '/events/create',
    '/events/create/',
    '/events/show',
    '/events/show/',
]