import React, {useContext, useEffect, useState} from 'react'
import {Button, Card, Checkbox, FormControl, FormLabel, Grid, Input, List, ListItem} from "@mui/joy";

import {CLayout} from "../../../components";
import {jwtDecode} from "jwt-decode";
import {Save} from "@mui/icons-material";
import {post} from "../../../api/api";
import {TitleContext} from "../../../components/Layout";

export const VCreateAbonnement = () => {
    const { setTitle } = useContext(TitleContext) || {}
    const [scopes, setScopes] = useState<string[]>([])
    const [selectedScopes, setSelectedScopes] = useState<string[]>([]);

    const submit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const data = Object.fromEntries(formData.entries());
        console.log({
            ...data,
            scopes: selectedScopes
        })

        void post({url: 'subscription_plans', data: {
            ...data,
            scopes: selectedScopes
        }})
            .then(res => window.location.href = '/plans')
            .catch(err => console.log(err))
    };

    const handleScopeChange = (scope: string) => {
        setSelectedScopes((prevScopes) =>
            prevScopes.includes(scope)
                ? prevScopes.filter((item) => item !== scope)
                : [...prevScopes, scope]
        );
    };

    useEffect(() => {
        const token = localStorage.getItem("token") || '';
        const decode: any = jwtDecode(token)
        if (!decode.roles.includes('ROLE_ADMIN')) {
            window.location.href = '/'
        }

        setScopes(decode?.scopes || [])
    }, []);

    useEffect(() => {
        if (setTitle) {
            setTitle('Ajouter un abonnement')
        }
    }, [setTitle]);

    return (
        <CLayout>
            <section id={"VCreateAbonnement"}>
                <form onSubmit={submit}>
                    <Grid container spacing={2}>
                        <Grid xs={12}>
                            <Card className={'cards'}>
                                <Grid container spacing={2}>
                                    <Grid xs={12}>
                                        <FormControl>
                                            <FormLabel>
                                                Nom
                                            </FormLabel>
                                            <Input
                                                name={'name'}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={12}>
                                        <FormControl>
                                            <FormLabel>
                                                Stipe ID
                                            </FormLabel>
                                            <Input
                                                name={'stripe_plan_id'}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={12}>
                                        <FormLabel>
                                            Scope
                                        </FormLabel>
                                        <List>
                                            {scopes.map((scope: string, key: number) => (
                                                <ListItem key={key}>
                                                    <Checkbox
                                                        value={scope}
                                                        label={scope}
                                                        checked={selectedScopes.includes(scope)}
                                                        onChange={() => handleScopeChange(scope)}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Grid>

                                    <Grid xs={4} xsOffset={8}>
                                        <Button type={'submit'} className={'float-right'}>
                                            <Save className={'!mr-2'}/> Enregistrer
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </form>
            </section>
        </CLayout>
    )
}