import React from "react";
import {Button, Grid, Typography} from "@mui/joy";
import {useTranslation} from "react-i18next";
import Logo from "../../assets/l-logo.svg";
import {ArrowBack} from "@mui/icons-material";

export const CNotFound = (): React.ReactElement => {
    const {t} = useTranslation();

    const redirect = () => {
        const token = localStorage.getItem("token");
        if (token) {
            window.location.href = '/';
        } else {
            window.location.href = '/login';
        }
    }

    return (
        <section className={'CNotFound mt-16'}>
            <Grid container spacing={2}>
                <Grid xs={12} className={'flex flex-col gap-2 items-center'}>
                    <img src={Logo} width={100} height={100} alt={'logo_eeven'}/>
                    <Typography className={'!font-light'} level={'h2'}>404</Typography>
                </Grid>

                <Grid xs={12} className={'text-center'}>
                    <Typography className={'!font-light w-1/4 !m-auto'} level={'h3'}>
                        Oops ! La page que vous avez demandée semble ne pas exister.
                    </Typography>
                </Grid>

                <Grid xs={4} xsOffset={4} className={'text-center'}>
                    <Button className={'submit'} onClick={redirect}>
                        <ArrowBack />
                        {t('Retour à l\'accueil')}
                    </Button>
                </Grid>
            </Grid>
        </section>
    )
}