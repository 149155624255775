import React, {useEffect, useState} from "react";
import {Bar, Line} from "react-chartjs-2";
import {Chart, registerables} from "chart.js";
import {CLayout} from "../../components";
import {post} from "../../api/api";
import moment from "moment";
import {jwtDecode} from "jwt-decode";

Chart.register(...registerables);

interface SubCategory {
    numberOfEvents: number;
    subCategory: string;
}

interface LocationData {
    localisation: string;
    subCategoriesPerLocation: SubCategory[];
}

interface KPIData {
    location: string;
    globalAverageViews: number;
    globalAverageLikes: number;
    globalAverageShares: number;
    globalAverageInterests: number;
}

interface FunctionResult {
    name: string;
    parameters: {
        start: string;
        end: string;
    };
    results: any[];
}

interface SubCategoryStats {
    subCategory: string;
    location: string;
    averageViewsPerCategory: number;
    averageLikesPerCategory: number;
    averageSharesPerCategory: number;
    averageInterestsPerCategory: number;
}

interface OrganizerStats {
    subCategory: string;
    location: string;
    totalOrganizerViews: number;
    totalOrganizerLikes: number;
    totalOrganizerShares: number;
    totalOrganizerInterests: number;
    totalEvents: number;
}

interface EventTimeSlot {
    localisation: string;
    subCategory: string;
    startHour: number;
    endHour: number;
    eventCount: number;
}

export const VDashboard = () => {
    const [start] = useState(moment().startOf('year').subtract(1, 'year').format('YYYY-MM-DDTHH:mm'));
    const [end] = useState(moment().startOf('year').add(1, 'year').format('YYYY-MM-DDTHH:mm'));
    const [organizer, setOrganizer] = useState('');
    const [countSimilarEventsBySubcategory, setCountSimilarEventsBySubcategory] = useState<LocationData[]>([]);
    const [globalAverageInterestsPerLocation, setGlobalAverageInterestsPerLocation] = useState<KPIData[]>([]);
    const [eventTimeSlots, setEventTimeSlots] = useState<EventTimeSlot[]>([]);
    const [organizerStats, setOrganizerStats] = useState<OrganizerStats[]>([]);
    const token = localStorage.getItem('token') ?? '';
    const [globalStats, setGlobalStats] = useState<SubCategoryStats[]>([]);


    useEffect(() => {
        if (token) {
            const decode: any = jwtDecode(token);
            setOrganizer(decode.name);
        }
    }, [token]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await post({
                    url: 'functions',
                    data: {
                        functions: [
                            {
                                name: "countSimilarEventsBySubcategory",
                                parameters: {start, end}
                            },
                            {
                                name: "globalAverageInterestsPerLocation",
                                parameters: {start, end}
                            },
                            {
                                name: "getGlobalAverageWithLocationForSubcategories",
                                parameters: {start, end}
                            },
                            {
                                name: "organizerStatsPerCategoryAndLocation",
                                parameters: {start, end, organizerName: organizer}
                            },
                            {
                                name: "eventTimeSlots",
                                parameters: {start, end}
                            }
                        ]
                    }
                });

                if (response.status === 201) {
                    processFunctionResults(response.data.functions);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [start, end, organizer]);

    const processFunctionResults = (functions: FunctionResult[]) => {
        functions.forEach(func => {
            if (func.name === "countSimilarEventsBySubcategory") {
                setCountSimilarEventsBySubcategory(func.results as LocationData[]);
            }
            if (func.name === "globalAverageInterestsPerLocation") {
                setGlobalAverageInterestsPerLocation(func.results as KPIData[]);
            }
            if (func.name === "getGlobalAverageWithLocationForSubcategories") {
                setGlobalStats(func.results as SubCategoryStats[]);
            }
            if (func.name === "organizerStatsPerCategoryAndLocation") {
                setOrganizerStats(func.results as OrganizerStats[]);
            }
            if (func.name === "eventTimeSlots") {
                setEventTimeSlots(func.results as EventTimeSlot[]);
            }
        });
    };

    const generateChartData = () => {
        if (countSimilarEventsBySubcategory.length === 0) {
            return {labels: [], datasets: []};
        }

        const labels = countSimilarEventsBySubcategory.flatMap(location =>
            location.subCategoriesPerLocation.map(subCat => subCat.subCategory)
        );

        const data = countSimilarEventsBySubcategory.flatMap(location =>
            location.subCategoriesPerLocation.map(subCat => subCat.numberOfEvents)
        );

        return {
            labels,
            datasets: [
                {
                    label: "Nombre d'événements",
                    data,
                    backgroundColor: "rgba(254, 64, 25, 1)",
                }
            ]
        };
    };

    const generateComparisonChartData = (keyOrganizer: any, keyAverage: any) => {
        const labels = globalStats.map(stat => stat.subCategory);

        // @ts-ignore
        const globalData = globalStats.map(stat => stat[keyAverage]);
        const organizerData = labels.map(label => {
            const organizerStat: any = organizerStats.find(stat => stat.subCategory === label);
            // @ts-ignore
            return organizerStat ? organizerStat[keyOrganizer] : 0;
        });

        return {
            labels,
            datasets: [
                {
                    label: "Moyenne du site",
                    data: globalData,
                    backgroundColor: "rgba(254, 64, 25, 1)",
                },
                {
                    label: "Moyenne de l'organisateur",
                    data: organizerData,
                    backgroundColor: "rgba(171, 190, 222, 1)",
                }
            ]
        };
    };

    const generateEventTimeSlotsChartData = (key: any) => {
        // Regrouper les événements par startHour
        const aggregatedData = eventTimeSlots.reduce((acc, slot: any) => {
            if (acc[slot[key]]) {
                acc[slot[key]] += slot.eventCount;
            } else {
                acc[slot[key]] = slot.eventCount;
            }
            return acc;
        }, {} as Record<number, number>);

        // Transformer les données en tableau utilisable par Chart.js
        const labels = Object.keys(aggregatedData).map(hour => `${hour}h`);
        const data = Object.values(aggregatedData);

        return {
            labels,
            datasets: [
                {
                    label: "Nombre d'événements par heure",
                    data,
                    borderColor: "rgba(54, 162, 235, 1)",
                    backgroundColor: "rgba(54, 162, 235, 0.2)",
                    fill: true,
                    tension: 0.4
                }
            ]
        };
    };

    return (
        <CLayout>
            <section id={"VDashboard"}>
                <div className="bg-[#0E102B] text-white min-h-screen p-6">
                    {globalAverageInterestsPerLocation.map((kpi, index) => (
                        <div>
                            <h2 className="text-lg font-semibold mb-2">📈 Statistique moyenne sur {kpi.location}</h2>
                            <div key={index} className="grid grid-cols-4 gap-4 mb-6">
                                <div className="bg-[#131537] p-6 rounded-lg shadow-lg text-center">
                                    <h3 className="text-lg font-semibold mb-2">👀 Vues</h3>
                                    <p className="text-3xl font-bold">{kpi.globalAverageViews}</p>
                                </div>
                                <div className="bg-[#131537] p-6 rounded-lg shadow-lg text-center">
                                    <h3 className="text-lg font-semibold mb-2">❤️ Likes</h3>
                                    <p className="text-3xl font-bold">{kpi.globalAverageLikes}</p>
                                </div>
                                <div className="bg-[#131537] p-6 rounded-lg shadow-lg text-center">
                                    <h3 className="text-lg font-semibold mb-2">🔁 Partages</h3>
                                    <p className="text-3xl font-bold">{kpi.globalAverageShares}</p>
                                </div>
                                <div className="bg-[#131537] p-6 rounded-lg shadow-lg text-center">
                                    <h3 className="text-lg font-semibold mb-2">🎟 Clics Billetterie</h3>
                                    <p className="text-3xl font-bold">{kpi.globalAverageInterests}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                    <h2 className="text-3xl font-bold mb-6">📊 Statistiques Premium</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
                        <div className="bg-[#131537] p-4 rounded-lg shadow-lg">
                            <h2 className="text-lg font-semibold mb-2">📊 Événements Similaires</h2>
                            <Bar data={generateChartData()}/>
                        </div>
                        <div className="bg-[#131537] p-4 rounded-lg shadow-lg">
                            <h2 className="text-lg font-semibold mb-2">📊 Comparaison des Vues</h2>
                            <Bar data={generateComparisonChartData('totalOrganizerViews', 'averageViewsPerCategory')}/>
                        </div>
                        <div className="bg-[#131537] p-4 rounded-lg shadow-lg">
                            <h2 className="text-lg font-semibold mb-2">🕒 Tranche Horaire Populaire de début</h2>
                            <Line data={generateEventTimeSlotsChartData('startHour')}/>
                        </div>
                        <div className="bg-[#131537] p-4 rounded-lg shadow-lg">
                            <h2 className="text-lg font-semibold mb-2">🕒 Tranche Horaire Populaire de fin</h2>
                            <Line data={generateEventTimeSlotsChartData('endHour')}/>
                        </div>
                    </div>
                    <h2 className="text-3xl font-bold mb-6">📊 Comparaison avec la Moyenne du Marché</h2>
                    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-6">
                        <div className="bg-[#131537] p-4 rounded-lg shadow-lg">
                            <h2 className="text-lg font-semibold mb-2">📊 Vues vs Moyenne</h2>
                            <Bar data={generateComparisonChartData('totalOrganizerViews', 'averageViewsPerCategory')}/>
                        </div>
                        <div className="bg-[#131537] p-4 rounded-lg shadow-lg">
                            <h2 className="text-lg font-semibold mb-2">❤️ Likes vs Moyenne</h2>
                            <Bar data={generateComparisonChartData('totalOrganizerLikes', 'averageLikesPerCategory')}/>
                        </div>
                        <div className="bg-[#131537] p-4 rounded-lg shadow-lg">
                            <h2 className="text-lg font-semibold mb-2">🔁 Partages vs Moyenne</h2>
                            <Bar
                                data={generateComparisonChartData('totalOrganizerShares', 'averageSharesPerCategory')}/>
                        </div>
                        <div className="bg-[#131537] p-4 rounded-lg shadow-lg">
                            <h2 className="text-lg font-semibold mb-2">🎟 Clics vs Moyenne</h2>
                            <Bar
                                data={generateComparisonChartData('totalOrganizerInterests', 'averageInterestsPerCategory')}/>
                        </div>
                    </div>
                </div>
            </section>
        </CLayout>
    );
};