import React, { useState, useEffect } from 'react'
import {useTranslation} from 'react-i18next'
import {jwtDecode} from 'jwt-decode'
import {Autocomplete, Breadcrumbs, Card, Chip, Divider, Grid, IconButton, Table, Typography} from "@mui/joy";
import {ChevronRight, Visibility} from "@mui/icons-material";
import {Link} from "react-router-dom";

import { CLayout } from '../../components'
import {get} from '../../api/api'
import './style.scss'
import {Pagination} from "@mui/material";

export const VListUsers = () => {
    const { t } = useTranslation()

    const [users, setUsers] = useState([])
    const [filteredUsers, setFilteredUsers] = useState([])
    const [pagination, setPagination] = useState<number>(0)
    const [page, setPage] = useState(1);
    const [plans, setPlans] = useState([])
    const [selectedPlan, setSelectedPlan] = useState<number>(0)

    const handleChange = (event: any, value: number) => {
        setPage(value);
    };

    useEffect(() => {
        document.title = `EevenPro - ${t('Liste des utilisateurs')}`
    }, [t]);

    useEffect(() => {
        void get({url: `users?page=${page}`})
            .then(res => {
                setPagination(Math.ceil(parseInt((res.headers['content-range']).split('/')[1]) / 20))
                setUsers(res.data)
                setFilteredUsers(res.data)
            })
            .catch(err => console.log(err))
    }, [page]);

    useEffect(() => {
        const token = localStorage.getItem('token') || ''
        const decode: any = jwtDecode(token)

        if (!decode.roles.includes('ROLE_ADMIN')) {
            window.location.href = '/'
        }

        void get({url: 'subscription_plans'})
            .then(res => setPlans(res.data))
            .catch(err => console.log(err))
    }, []);

    useEffect(() => {
        setFilteredUsers(users)
        if (selectedPlan) {
            setFilteredUsers(
                users.filter((user: any) => user.subscription_plans.includes(`/subscription_plans/${selectedPlan}`))
            )
        }
    }, [users, selectedPlan]);

    return (
        <CLayout>
            <section id={'VListUsers'}>
                <Card className={'rounded-none my-4 drop-shadow-lg'} variant={'outlined'}>
                    <Grid container spacing={2} className={'items-center'}>
                        <Grid xs={4}>
                            <Breadcrumbs separator={<ChevronRight />}>
                                <Link to={'/'}>Eeven PRO</Link>
                                <Typography>Liste des utilisateurs</Typography>
                            </Breadcrumbs>
                        </Grid>

                        <Grid xs={4}>
                            <Autocomplete onChange={(e, newValue: any) => {
                                setSelectedPlan(newValue?.id || 0)
                            }} getOptionLabel={(option: any) => option.name || ''} isOptionEqualToValue={(option: any, value: any) => option.id === value.id} options={plans} variant={'outlined'} placeholder={t('Plan d\'abonnement')} />
                        </Grid>

                        <Grid xs={4}>
                            <Pagination className={'!text-black'} count={pagination} shape="rounded" onChange={handleChange}/>
                        </Grid>
                    </Grid>
                </Card>

                <Grid container spacing={2} className={'justify-between items-center'}>
                    <Grid xs={4}>
                        <Typography level={'h4'} className={'!mt-4 !text-3xl !text-black'}>
                            Utilisateurs
                        </Typography>
                        <Divider className={'!w-[60%] !bg-black !h-[2px] !mt-1 !mb-4'} />
                    </Grid>
                </Grid>

                <Table>
                    <thead>
                    <tr>
                        <th>Nom</th>
                        <th>Email</th>
                        <th>Roles</th>
                        <th>Actions</th>
                    </tr>
                    </thead>

                    <tbody>
                        {filteredUsers.map((user: any, index: number) => (
                            <tr key={index}>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>
                                    {user.roles.map((role: any, index: number) => {
                                        return index < 2 && (<Chip variant={'solid'} color={role === 'ROLE_ADMIN' ? 'success' : 'primary'} className={'mr-4'} key={index}>{role}</Chip>)
                                    })}
                                </td>
                                <td>
                                    <IconButton onClick={() => {
                                        window.location.href = `/users/show?id=${user.id}`;
                                    }}>
                                        <Visibility/>
                                    </IconButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </section>
        </CLayout>
    )
}